import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="October 2020"
      subnav="release-notes">
      <div id="October2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Spooky season is here (and maybe to stay). With it came fixes to some of our
          scariest bugs: like Auto Layout trap doors in the Figma Library,
           Radio ghost's selecting, and more.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.5.4"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.5.4"
            />

            <div className={styles.changesList} />
            <Added>
              New{' '}
              <Link href="/components/icons/ui-controls/design">
                UI Control icons
              </Link>{' '}
              for adaptable layouts and menus.
            </Added>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="0.55.0"
              versionUrl="https://github.com/hudl/jarvis/releases/tag/v0.55.0"
            />
            <div className={styles.changesList}>
              <Added>All those icons we spoke about earlier.</Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Fixed>
                Both the single{' '}
                <Link href="/components/forms/radio/code">Radio</Link> and the
                Radio Group to display which value was selected.
              </Fixed>
              <Fixed>A bug that made our homepage display incorrectly on Windows machines. Thanks,
                {' '}<Link href="https://github.com/jhofker">Jordan</Link>!</Fixed>
              <Fixed>An issue that preventing a default selection in our component previews and platform tabs.</Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Added>
                All the variants of some of our most complex components, in
                preparation for the new Variant feature.
              </Added>
              <Improved>
                Auto Layout support on a number of components.
              </Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
